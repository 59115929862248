


















import { Component, Vue } from 'vue-property-decorator'
import SelectBox from '@/components/SelectBox.vue'
import { Exposure, ExposureData } from '@/store/modules/walkthrough/types/pre-test/exposure'

@Component({
  components: {
    SelectBox
  }
})
export default class Step3 extends Vue {
  private selected: Array<keyof ExposureData> = []

  private updateVal (ev: Array<keyof ExposureData>): void {
    this.selected = ev
  }

  private mounted (): void {
    const previousAnswer = this.$store.getters['walkthrough/exposure'] as Exposure
    if (previousAnswer) {
      const previouslySelectedExposures = previousAnswer.selected()
      Array.prototype.push.apply(this.selected, previouslySelectedExposures);
      (this.$refs.selectBox as SelectBox).setVal(previouslySelectedExposures as [])
      this.selected = previouslySelectedExposures as []
    }
  }

  private goTo ():void{
    const exposure = Exposure.empty()
    this.selected.forEach((selectedExposure) => {
      console.log('selected', selectedExposure)
      if (!Object.prototype.hasOwnProperty.call(exposure, selectedExposure)) {
        return
      }
      exposure.set(selectedExposure, true)
    })
    this.$store.dispatch('walkthrough/setExposure', exposure)
    // tests
    this.$router.push({ name: 'walkthrough 1' })
  }
}
